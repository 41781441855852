import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Container } from "../components/Layout/Container";
import { SectionTitle } from "../components/Layout/SectionTitle";
import { Section } from "../components/Layout/Section";
import GalleryList from "../components/Gallery/GalleryList";
import GalleryItem from "../components/Gallery/GalleryItem";
import { pluralizeWord } from "../../utils/helpers";

const Index = ({pageContext, data}) => {
  console.log(pageContext.name.toLowerCase());
  const galleries = data.allGallery.nodes
  return (
    <Layout insidePage breadCrumbLinks={pageContext.breadCrumb}>
      <Container width="full-size">
          <Section>
            <SectionTitle>
              {pluralizeWord(pageContext.name.toLowerCase())}
            </SectionTitle>
            { galleries.length > 0 && <GalleryList>
              {galleries.map(p => {
                const { title, value } = p.locales.find(l => l.iso === pageContext.lang)
                return <GalleryItem key={p.uid} showInfo image={p.imageFile} subText="View all" title={title} projectUrl={value} />
              })}
            </GalleryList>}
          </Section>
      </Container>
    </Layout>
  )
}
export const query = graphql`
    query($tagUid: String) {
        allGallery(
            filter: {tags: {elemMatch: {uid: {eq: $tagUid}}}}
        ) {
            nodes {
                uid
                locales {
                    iso
                    title
                    value
                }
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`
export const Head = () => <Seo title="List of all areas" />
export default Index
